/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "./Assets/Styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "./Assets/Styles/fonts";
@import "./Assets/Styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "./Assets/Styles/mixins/animation";
@import "./Assets/Styles/mixins/badges";
@import "./Assets/Styles/mixins/buttons";
@import "./Assets/Styles/mixins/misc";
@import "./Assets/Styles/mixins/color-functions";
@import "./Assets/Styles/mixins/cards";
@import "./Assets/Styles/mixins/blockqoute";

/* === Core Styles === */
@import "./Assets/Styles/typography";
@import "./Assets/Styles/reset";
@import "./Assets/Styles/responsive";
@import "./Assets/Styles/misc";
@import "./Assets/Styles/utilities";
@import "./Assets/Styles/demo";
@import "./Assets/Styles/spinner";
@import "./Assets/Styles/dashboard";

/* === Components === */

@import "./Assets/Styles/Components/checkbox-radio";
@import "./Assets/Styles/Components/forms";
@import "./Assets/Styles/Components/icons";
@import "./Assets/Styles/Components/tables";
@import "./Assets/Styles/Components/buttons";
@import "./Assets/Styles/Components/breadcrumbs";
@import "./Assets/Styles/Components/badges";
@import "./Assets/Styles/Components/cards";
@import "./Assets/Styles/Components/preview";
@import "./Assets/Styles/Components/lists";
@import "./Assets/Styles/Components/todo-list";
@import "./Assets/Styles/Components/dropdowns";
@import "./Assets/Styles/Components/loaders/loaders";

@import "./Assets/Styles/Components/landing-screens/auth";

/* === Plugin Overrides === */
@import "./Assets/Styles/Components/plugin-overrides/slick-carousel";
@import "./Assets/Styles/Components/plugin-overrides/jquery-jvectormap";

/* === Layout === */
@import "./Assets/Styles/navbar";
@import "./Assets/Styles/sidebar";
@import "./Assets/Styles/footer";
@import "./Assets/Styles/layout";

// additional stayling

.ancorLink {
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.badge_custom {
  align-items: flex-end;
  display: flex;
  font-size: medium;
}
.adminImgCon {
  width: 100%;
  display: flex;
  justify-content: center;
}


.pagination{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.hiddenClass {
  display: none;
}

.modelClosebtn {
  background-color: transparent;
  color: white;
  border: none;
  position: relative;
  right: 0;
}

.modelClosebtn_Wrapper {
  display: flex;
  justify-content: flex-end;
}

.modal-content {
  // border: none;
  background-color: #0c0d12;
  color: #fff;
}

.close {
  color: white;
  &:hover {
    color: white;
  }
}
.ps-real {
  position: relative;
}

.passwordIsvisible {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #0078c1;
  cursor: pointer;
}
.passwordVisible {
  position: relative;
  bottom: 32px;
  left: 470px;
  color:#0078c1;
  cursor: pointer;
}
.errorMessage {
  margin-top: 6px;
  color: red;
}

.errorPassword{
  color: red;
  margin-top: -41px;
}

.profileImage {
position: relative;
}

.loginButton{
  margin-top: 3rem !important;
}
.formInput{
  margin-top: 10px;
}
.saveButton{
  margin-top: 20px;
}
.userBox{
  // text-align: center;
  margin-left: 50px;
  padding: 90px;
  height: 500px;
 
  // border: 1px solid#0078c1;
}
